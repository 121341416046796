<template>
  <div class="d-flex mt-4">
    <div class="flex-shrink-0">
      <v-avatar>
        <v-img src="@/assets/img/team-4.jpg"></v-img>
      </v-avatar>
    </div>
    <div class="flex-grow-1 ms-4">
      <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
        {{ comment.createdByName }}
      </h5>
      <p class="text-sm text-body">
        {{ comment.text }}
      </p>
      <div class="d-flex align-center">
        <!-- <v-icon size="16"
          >ni ni-like-2 me-1 mt-0 cursor-pointer text-body</v-icon
        >
        <span class="text-sm me-2 text-body">3 likes</span> -->
        <!-- <v-icon size="16"
          >ni ni-curved-next me-1 mt-0 cursor-pointer text-body</v-icon
        > -->
        <!-- <span class="text-sm me-2 text-body">2 shares</span> -->
      </div>
    </div>
  </div>

  <!-- 
                <div class="d-flex mt-5">
                  <div class="flex-shrink-0">
                    <v-avatar>
                      <v-img src="@/assets/img/team-5.jpg"></v-img>
                    </v-avatar>
                  </div>
                  <div class="flex-grow-1 ms-4">
                    <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
                      Jessica Stones
                    </h5>
                    <p class="text-sm text-body">
                      Society has put up so many boundaries, so many limitations
                      on what’s right and wrong that it’s almost impossible to
                      get a pure thought out. It’s like a little kid, a little
                      boy.
                    </p>
                    <div class="d-flex align-center">
                      <v-icon size="16"
                        >ni ni-like-2 me-1 mt-0 cursor-pointer text-body</v-icon
                      >
                      <span class="text-sm me-2 text-body">10 likes</span>
                      <v-icon size="16"
                        >ni ni-curved-next me-1 mt-0 cursor-pointer
                        text-body</v-icon
                      >
                      <span class="text-sm me-2 text-body">1 share</span>
                    </div>
                  </div>
                </div> -->
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    comment: {
      required: true,
    },
  },
};
</script>

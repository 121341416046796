import { render, staticRenderFns } from "./PageLessonPreview.vue?vue&type=template&id=2510d1d3&"
import script from "./PageLessonPreview.vue?vue&type=script&lang=js&"
export * from "./PageLessonPreview.vue?vue&type=script&lang=js&"
import style0 from "./PageLessonPreview.vue?vue&type=style&index=0&id=2510d1d3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col lg="8" cols="12">
          <v-card
            class="card-shadow border-radius-xl"
            v-if="selectedTopic == null && selectedTopic == undefined"
          ></v-card>
          <v-card class="card-shadow border-radius-xl" v-else>
            <div class="border-bottom d-flex align-center px-4 py-4">
              <div class="d-flex align-center">
                <a href="javascript:;" class="text-decoration-none">
                  <v-avatar size="48" rounded>
                    <v-img
                      src="@/assets/img/team-4.jpg"
                      alt="profile-image"
                    ></v-img>
                  </v-avatar>
                </a>
                <div class="mx-4">
                  <a
                    href="javascript:;"
                    class="
                      text-dark
                      font-weight-600
                      text-sm text-decoration-none
                    "
                    >{{userData["FIRST_NAME"] }}</a
                  >
                  <small class="d-block text-muted">{{
                    formatXDate2(selectedTopic.createdAt)
                  }}</small>
                </div>
              </div>
              <div class="text-end ms-auto">
                <!-- <v-btn
                  outlined
                  color="#fff"
                  class="font-weight-bolder bg-gradient-warning py-4 px-7"
                  small
                >
                  Чат бичиx
                </v-btn> -->
              </div>
            </div>
            <div class="px-4 py-4">
              <p class="mb-6 text-body">
                {{ selectedTopic.description }}
              </p>
              <iframe
                width="100%"
                height="400"
                v-if="
                  selectedTopic.videoUrl != null &&
                  selectedTopic.videoUrl != '' &&
                  selectedTopic.videoUrl.toLowerCase().indexOf('youtube') != -1
                "
                v-bind:src="getYoutubeIframe(selectedTopic.videoUrl)"
                frameborder="0"
                allowfullscreen
              ></iframe>
              <div
                id="iframecontainer"
                v-html="selectedTopic.slideUrl"
              ></div>
              <v-row class="align-center px-2 mt-6 mb-2">
                <v-col sm="6">
                  <div class="d-flex">
                    <div class="d-flex align-center">
                      <v-icon size="16"
                        >ni ni-like-2 me-1 text-body cursor-pointer</v-icon
                      >
                      <span class="text-sm text-body me-4">{{
                        selectedTopic.numberOfLikes
                      }}</span>
                    </div>
                    <div class="d-flex align-center">
                      <v-icon size="16"
                        >ni ni-chat-round me-1 text-body cursor-pointer</v-icon
                      >
                      <span class="text-sm text-body me-4">
                        <span
                          v-if="
                            selectedTopic.comments != undefined &&
                            selectedTopic.comments != null
                          "
                          >{{ selectedTopic.numberOfComments }}</span
                        >
                        <span class="text-sm text-body me-4" v-else>0</span>
                      </span>
                    </div>
                    <!-- <div class="d-flex align-center">
                      <v-icon size="16"
                        >ni ni-curved-next me-1 text-body cursor-pointer</v-icon
                      >
                      <span class="text-sm text-body me-4">12</span>
                    </div> -->
                  </div>
                </v-col>
                <v-col sm="6">
                  <div class="d-flex align-center">
                    <div class="d-flex align-items-center ms-auto">
                      <v-avatar size="24" class="border border-white ms-n2">
                        <img src="@/assets/img/team-5.jpg" alt="Avatar" />
                      </v-avatar>
                      <v-avatar size="24" class="border border-white ms-n2">
                        <img src="@/assets/img/team-2.jpg" alt="Avatar" />
                      </v-avatar>
                      <v-avatar size="24" class="border border-white ms-n2">
                        <img src="@/assets/img/team-1.jpg" alt="Avatar" />
                      </v-avatar>
                    </div>
                    <small class="ps-2 font-weight-bold text-body"
                      >болон {{ selectedTopic.numberOfLikes }} +</small
                    >
                  </div>
                </v-col>
              </v-row>
              <div class="mb-1">
                <hr class="horizontal dark mt-1 mb-5" />
                <template
                  v-if="
                    selectedTopic.comments != undefined &&
                    selectedTopic.comments != null
                  "
                >
                  <comment
                    v-for="(comment, cindex) in selectedTopic.comments"
                    :key="selectedTopic.id + 'comment' + cindex"
                    :comment="comment"
                  >
                  </comment
                ></template>

                <div class="d-flex mt-6">
                  <div class="flex-shrink-0">
                    <v-avatar>
                      <v-img src="@/assets/img/team-4.jpg"></v-img>
                    </v-avatar>
                  </div>
                  <div class="flex-grow-1 ms-4">
                    <v-text-field
                      v-model="currentComment"
                      @keydown.enter="_writeComment"
                      outlined
                      placeholder="Энд сэтгэгдлээ бичиx"
                      class="border"
                    >
                    </v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="4" cols="12">
          <v-card class="card-shadow border-radius-xl h-100">
            <div class="px-4 pt-4">
              <div class="d-flex flex-row">
                <h6 class="mb-0 text-h6 text-typo font-weight-bold">
                  Xичээлийн сэдвүүд
                </h6>
              </div>
              <v-simple-table class="table border-radius-xl">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="1%"></th>
                      <th
                        v-for="(hname, hindex) in headerNames"
                        :key="'header' + hindex"
                        class="
                          text-uppercase text-secondary text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                      >
                        {{ hname }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :style="
                        selectedTopic.id == item.id
                          ? 'background:#ebebeb !important'
                          : ''
                      "
                      @click="openDetails(item)"
                      v-for="(item, i) in topics"
                      :key="item.id"
                      style="cursor: pointer"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        {{ item.title }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");
import Comment from "./Widgets/Comment";
import { sync } from "vuex-pathify";
export default {
  name: "smart-home",
  components: { Comment },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  mixins: [myfunction],
  computed:{
    ...sync("*"),
  },
  data() {
    return {
      currentComment: null,
      students: null,
      lesson: null,
      tab: null,
      tabClassGroups: null,
      tabTexts: ["Сэдвүүд", "Даалгавар", "Дүн"],
      chartConsumption: "chart-consumption",
      chartConsWeek: "chart-cons-week",
      switche: false,
      switch1: true,
      switch2: true,
      switch3: false,
      switch4: false,
      switch5: true,
      sliderValue: "",
      colIgnore: true,
      colWidth: 4,
      headerNames: [],
      topics: null,
      selectedTopic: null,
    };
  },
  methods: {
    _writeComment() {
      this.selectedTopic.ref
        .collection("comments")
        .doc()
        .set({
          createdAt: new Date(),
          createdByName: "XXX",
          createdByRef: "XXX",
          text: this.currentComment,
        })
        .then(() => {
          this.currentComment = null;
        });
    },
    _extendTable() {
      if (this.colIgnore == true) {
        this.colIgnore = false;
        this.colWidth = 12;
      } else {
        this.colIgnore = true;
        this.colWidth = 4;
      }
    },
    openDetails(item) {
      if (this.selectedTopic != item) {
        this.selectedTopic = item;
        if (item.comments == null) this.readComments(item);
      } else {
        console.log("dflkjdkkkk");
      }
      // if (this.selectedTopic.selectedOnce == undefined) {
      //   this.readComments(this.selectedTopic);
      //   this.selectedTopic.selectedOnce = true;
      // }
    },
    readComments(item) {
      item.ref.collection("comments").onSnapshot((docs) => {
        item.comments = [];
        docs.forEach((doc) => {
          let c = doc.data();
          c.id = doc.id;
          item.comments.push(c);
        });
      });
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
  },
  created() {
    fb.db
      .collection(this.path + "/topics")
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.topics = [];
        var counter = 0;
        docs.forEach((doc) => {
          let topic = doc.data();
          topic.id = doc.id;
          topic.ref = doc.ref;
          topic.comments = null;
          counter++;
          this.topics.push(topic);
          if (counter == 1) {
            this.openDetails(topic);
          }
        });
      });
  },
  mounted() {},
};
</script>
<style>
#iframecontainer iframe {
  width: 100% !important;
}
</style>
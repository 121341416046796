var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"8","cols":"12"}},[(_vm.selectedTopic == null && _vm.selectedTopic == undefined)?_c('v-card',{staticClass:"card-shadow border-radius-xl"}):_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"border-bottom d-flex align-center px-4 py-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"javascript:;"}},[_c('v-avatar',{attrs:{"size":"48","rounded":""}},[_c('v-img',{attrs:{"src":require("@/assets/img/team-4.jpg"),"alt":"profile-image"}})],1)],1),_c('div',{staticClass:"mx-4"},[_c('a',{staticClass:"text-dark font-weight-600 text-sm text-decoration-none",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.userData["FIRST_NAME"]))]),_c('small',{staticClass:"d-block text-muted"},[_vm._v(_vm._s(_vm.formatXDate2(_vm.selectedTopic.createdAt)))])])]),_c('div',{staticClass:"text-end ms-auto"})]),_c('div',{staticClass:"px-4 py-4"},[_c('p',{staticClass:"mb-6 text-body"},[_vm._v(" "+_vm._s(_vm.selectedTopic.description)+" ")]),(
                _vm.selectedTopic.videoUrl != null &&
                _vm.selectedTopic.videoUrl != '' &&
                _vm.selectedTopic.videoUrl.toLowerCase().indexOf('youtube') != -1
              )?_c('iframe',{attrs:{"width":"100%","height":"400","src":_vm.getYoutubeIframe(_vm.selectedTopic.videoUrl),"frameborder":"0","allowfullscreen":""}}):_vm._e(),_c('div',{attrs:{"id":"iframecontainer"},domProps:{"innerHTML":_vm._s(_vm.selectedTopic.slideUrl)}}),_c('v-row',{staticClass:"align-center px-2 mt-6 mb-2"},[_c('v-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("ni ni-like-2 me-1 text-body cursor-pointer")]),_c('span',{staticClass:"text-sm text-body me-4"},[_vm._v(_vm._s(_vm.selectedTopic.numberOfLikes))])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("ni ni-chat-round me-1 text-body cursor-pointer")]),_c('span',{staticClass:"text-sm text-body me-4"},[(
                          _vm.selectedTopic.comments != undefined &&
                          _vm.selectedTopic.comments != null
                        )?_c('span',[_vm._v(_vm._s(_vm.selectedTopic.numberOfComments))]):_c('span',{staticClass:"text-sm text-body me-4"},[_vm._v("0")])])],1)])]),_c('v-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-items-center ms-auto"},[_c('v-avatar',{staticClass:"border border-white ms-n2",attrs:{"size":"24"}},[_c('img',{attrs:{"src":require("@/assets/img/team-5.jpg"),"alt":"Avatar"}})]),_c('v-avatar',{staticClass:"border border-white ms-n2",attrs:{"size":"24"}},[_c('img',{attrs:{"src":require("@/assets/img/team-2.jpg"),"alt":"Avatar"}})]),_c('v-avatar',{staticClass:"border border-white ms-n2",attrs:{"size":"24"}},[_c('img',{attrs:{"src":require("@/assets/img/team-1.jpg"),"alt":"Avatar"}})])],1),_c('small',{staticClass:"ps-2 font-weight-bold text-body"},[_vm._v("болон "+_vm._s(_vm.selectedTopic.numberOfLikes)+" +")])])])],1),_c('div',{staticClass:"mb-1"},[_c('hr',{staticClass:"horizontal dark mt-1 mb-5"}),(
                  _vm.selectedTopic.comments != undefined &&
                  _vm.selectedTopic.comments != null
                )?_vm._l((_vm.selectedTopic.comments),function(comment,cindex){return _c('comment',{key:_vm.selectedTopic.id + 'comment' + cindex,attrs:{"comment":comment}})}):_vm._e(),_c('div',{staticClass:"d-flex mt-6"},[_c('div',{staticClass:"flex-shrink-0"},[_c('v-avatar',[_c('v-img',{attrs:{"src":require("@/assets/img/team-4.jpg")}})],1)],1),_c('div',{staticClass:"flex-grow-1 ms-4"},[_c('v-text-field',{staticClass:"border",attrs:{"outlined":"","placeholder":"Энд сэтгэгдлээ бичиx"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm._writeComment.apply(null, arguments)}},model:{value:(_vm.currentComment),callback:function ($$v) {_vm.currentComment=$$v},expression:"currentComment"}})],1)])],2)],1)])],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl h-100"},[_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"d-flex flex-row"},[_c('h6',{staticClass:"mb-0 text-h6 text-typo font-weight-bold"},[_vm._v(" Xичээлийн сэдвүүд ")])]),_c('v-simple-table',{staticClass:"table border-radius-xl",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"1%"}}),_vm._l((_vm.headerNames),function(hname,hindex){return _c('th',{key:'header' + hindex,staticClass:"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"},[_vm._v(" "+_vm._s(hname)+" ")])})],2)]),_c('tbody',_vm._l((_vm.topics),function(item,i){return _c('tr',{key:item.id,staticStyle:{"cursor":"pointer"},style:(_vm.selectedTopic.id == item.id
                        ? 'background:#ebebeb !important'
                        : ''),on:{"click":function($event){return _vm.openDetails(item)}}},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]},proxy:true}])})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }